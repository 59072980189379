import useFetch from "../../hooks/fetch/useFetch";
import { Link } from "react-router-dom";

import { createMarkup } from "../utils/utils";
import "./Trials.scss";

interface trialsProps {
  refProp: any;
}

const Trials: React.FC<trialsProps> = ({ refProp }) => {
  const { data } = useFetch<any>("trials");

  return (
    <section className="trials-container" ref={refProp}>
      {data && <h2>{data[0].acf.heading} </h2>}
      {data && <p className="first-paragraph">{data[0].acf.ingress}</p>}
      <div className="timeline">
        <div className="container right">
          <div className="content">
            {data && (
              <p dangerouslySetInnerHTML={createMarkup(data[0].acf.trialone)} />
            )}
          </div>
        </div>
        <div className="container right">
          <div className="content">
            {data && (
              <p
                dangerouslySetInnerHTML={createMarkup(data[0].acf.trialtwoa)}
              />
            )}
          </div>
        </div>
        <div className="container right">
          <div className="content">
            {data && (
              <p
                dangerouslySetInnerHTML={createMarkup(data[0].acf.trialtwob)}
              />
            )}
          </div>
        </div>
        <div className="container right">
          <div className="content">
            {data && (
              <p
                dangerouslySetInnerHTML={createMarkup(data[0].acf.trialthree)}
              />
            )}
          </div>
        </div>
      </div>
      {data && <p>{data[0].acf.text}</p>}
      <Link className="link" to="/lument_study_phase_one.pdf" target="_blank" download>
        Download Trial Phase 1
      </Link>
      <Link className="link" to="/lument_study_phase_two.pdf" target="_blank" download>
        Download Trial Phase 2
      </Link>
    </section>
  );
};

export default Trials;
