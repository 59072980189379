import useFetch from "../../../hooks/fetch/useFetch";
import "./Nav.scss";

interface NavProps {
  scroll: any;
}

const Nav: React.FC<NavProps> = ({ scroll }) => {
  const { data } = useFetch<any>("nav");
  console.log("nav", data);
  
  return (
    <div className="nav">
      <div className="nav-list">
        {data && (
          <button
            className="nav-button"
            type="button"
            onClick={() => scroll("concept")}
          >
            {data[0].acf.link_one}
          </button>
        )}
        {data && (
          <button
            className="nav-button"
            type="button"
            onClick={() => scroll("approach")}
          >
            {data[0].acf.link_two}
          </button>
        )}
        {data && (
          <button
            className="nav-button"
            type="button"
            onClick={() => scroll("market")}
          >
            {data[0].acf.link_three}
          </button>
        )}
        {data && (
          <button
            className="nav-button"
            type="button"
            onClick={() => scroll("trials")}
          >
            {data[0].acf.link_four}
          </button>
        )}
        {data && (
          <button
            className="nav-button"
            type="button"
            onClick={() => scroll("people")}
          >
            {data[0].acf.link_five}
          </button>
        )}
      </div>
    </div>
  );
};

export default Nav;
