import useFetch from "../../hooks/fetch/useFetch";

import { createMarkup } from "../utils/utils";

import React from "react";
import "./Market.scss";

interface MarketProps {
  refProp: any;
}

const Market: React.FC<MarketProps> = ({refProp}) => {
  const { data } = useFetch<any>("market");
  return (
    <section className="market-container" ref={refProp}>
      {data && <h2>{data[0].acf.main_heading}</h2>}
      {data && <p className="first-paragraph">{data[0].acf.ingress}</p>}
      {data && <p dangerouslySetInnerHTML={createMarkup(data[0].acf.text)} />}
    </section>
  );
};

export default Market;
