import ManagementTeam from "../ManagementTeam/ManagementTeam";
import BoardDirectors from "../BoardDirectors/BoardDirectors";
import AdvisoryBoard from "../AdvisoryBoard/AdvisoryBoard";
import "./People.scss";

interface peopleProps {
  refProp: any;
}

const People: React.FC<peopleProps> = ({ refProp }) => {
  return (
    <section className="people-container" ref={refProp}>
      <h2>People</h2>
      <ManagementTeam />
      <BoardDirectors />
      <AdvisoryBoard />
    </section>
  );
};
export default People;
