import React from "react";
import heroimg from "../../assets/abstract.png";
import "./Hero.scss";

interface HeroProps {}

const Hero: React.FC<HeroProps> = () => {
  return (
    <header className="hero">
      <picture className="image-container">
        <img className="image" src={heroimg} alt="abstract background" />
      </picture>
      <div className="heading-container">
        <h1>Lumentin<sup>®</sup> Unique <span className="heading-animation"> Contrast </span> </h1>
      </div>
    </header>
  );
};

export default Hero;
