import useFetch from "../../hooks/fetch/useFetch";

import "./Concept.scss";
import iconGut from "../../assets/icon_gut_w.png";
import icon3d from "../../assets/icon_3d_w.png";
import iconCompounder from "../../assets/icon_compounder_w.png";

interface ConceptProps {
  refProp: any;
}

const Concept: React.FC<ConceptProps> = ({ refProp }) => {
  const { data } = useFetch<any>("concept");

  return (
    <section className="concept-conatiner" ref={refProp}>
      <div className="content-container">
        {data && <h2>{data[0].acf.heading}</h2>}
        {data && <p>{data[0].acf.text}</p>}
        <div className="icons">
          <div className="icon-content-container">
            <img className="image" src={iconGut} alt="illustration" />
            <p className="text">Food-based contrast agent</p>
          </div>
          <div className="icon-content-container">
            <img className="image" src={icon3d} alt="illustration" />
            <p className="text">3D Virtual Endoscopy Software </p>
          </div>
          <div className="icon-content-container">
            <img className="image" src={iconCompounder} alt="illustration" />
            <p className="text">“Reconstitution Device</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Concept;
