import useFetch from "../../hooks/fetch/useFetch";
import { createMarkup } from "../utils/utils";
import "./QuoteBottom.scss";
import xray from "../../assets/xray.png";

const QuoteBottom = () => {
  const { data } = useFetch<any>("quotes");
  return (
    <section className="quote-bottom-container">
      <picture className="image-container">
        <img className="image" src={xray} alt="abstract background" />
      </picture>
      <div className="text-container">
        {data && (
          <p dangerouslySetInnerHTML={createMarkup(data[0].acf.quote_four)} />
        )}
      </div>
    </section>
  );
};

export default QuoteBottom;
