import useFetch from "../../hooks/fetch/useFetch";
import { createMarkup } from "../utils/utils";
import "./QuoteMiddleSecond.scss";
import ct from "../../assets/ct.png";

const QuoteMiddleSecond = () => {
  const { data } = useFetch<any>("quotes");
  return (
    <section className="quote-middle-container">
      <picture className="image-container">
        <img className="image" src={ct} alt="abstract background" />
      </picture>
      <div className="text-container">
        {data && (
          <p dangerouslySetInnerHTML={createMarkup(data[0].acf.quote_three)} />
        )}
      </div>
    </section>
  );
};

export default QuoteMiddleSecond;
