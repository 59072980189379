import useFetch from "../../hooks/fetch/useFetch";
import { createMarkup } from "../utils/utils";
import "./QuoteMiddle.scss";
import foam from "../../assets/foam.png";

const QuoteMiddle = () => {
  const { data } = useFetch<any>("quotes");
  return (
    <section className="quote-middle-container">
      <picture className="image-container">
        <img className="image" src={foam} alt="abstract background" />
      </picture>
      <div className="text-container">
        {data && (
          <p dangerouslySetInnerHTML={createMarkup(data[0].acf.quote_two)} />
        )}
      </div>
    </section>
  );
};

export default QuoteMiddle;
