import useFetch from "../../hooks/fetch/useFetch";
import "./Footer.scss";
import lab from "../../assets/lab.png";
import logo from "../../assets/lument_logo_black.png";

const Footer = () => {
  const { data } = useFetch<any>("footer");
  return (
    <section className="footer-container">
      <picture className="image-container">
        <img className="image" src={lab} alt="background" />
      </picture>
      <div className="contact-container">
        {data && <h4>{data[0].acf.heading}</h4>}
        <div className="contact-list">
          <ul>
            {data && <li>{data[0].acf.contact_info.company_name}</li>}
            {data && <li>{data[0].acf.contact_info.adress}</li>}
            {data && <li>{data[0].acf.contact_info.box}</li>}
            {data && (
              <li>
                {data[0].acf.contact_info.postal_code}{" "}
                {data[0].acf.contact_info.city}
              </li>
            )}
          </ul>
          <ul>
            {data && <li>{data[0].acf.contact_info.phone_number}</li>}
            {data && <li>{data[0].acf.contact_info.email}</li>}
            {data && <li>{data[0].acf.contact_info.text}</li>}
            <li>
              {data && (
                <a
                  href={data[0].acf.contact_info.link.url}
                  target={data[0].acf.contact_info.link.target}
                >
                  {data[0].acf.contact_info.link.title}
                </a>
              )}
            </li>
          </ul>
          <img className="logo" src={logo} alt="logo" />
        </div>
      </div>
    </section>
  );
};

export default Footer;
