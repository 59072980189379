import useFetch from "../../hooks/fetch/useFetch";

import "./Approach.scss";
import iconPowder from "../../assets/icon_powder_b.png";
import iconGlas from "../../assets/icon_glas_b.png";
import iconScan from "../../assets/icon_scan_b.png";
import iconXray from "../../assets/icon_xray_b.png";
import icon3d from "../../assets/icon_3d_b.png";
import { createMarkup } from "../utils/utils";

interface ApproachProps {
  refProp: any;
}

const Approach: React.FC<ApproachProps> = ({ refProp }) => {
  const { data } = useFetch<any>("approach");

  return (
    <section className="approach-container" ref={refProp}>
      <div className="content-container">
        {data && <h2>{data[0].acf.heading}</h2>}
        {data && (
          <p className="first-paragraph">{data && data[0].acf.ingress}</p>
        )}
        {data && <p dangerouslySetInnerHTML={createMarkup(data[0].acf.text)} />}
      </div>
      <h3>Lumentin® at the clinic</h3>
      <div className="cards">
        <div className="card-container">
          <img className="card-icon_small" src={iconPowder} alt="icon" />
          <p className="card-text">
            Lumentin<sup>®</sup> contrast agent is conveniently delivered as a
            powder packed in portion-pouches.
          </p>
        </div>
        <div className="card-container">
          <img className="card-icon" src={iconGlas} alt="icon" />
          <p className="card-text">
            The powder is freshly prepared into a drinkable foam by the Lumentin
            <sup>®</sup> reconstitution device.
          </p>
        </div>
        <div className="card-container">
          <img className="card-icon" src={iconScan} alt="icon" />
          <p className="card-text">
            After drinking the contrast agent the patient is examined in the
            CT-scanner.
          </p>
        </div>
        <div className="card-container">
          <img className="card-icon" src={iconXray} alt="icon" />
          <p className="card-text">
            Images show the small intestine in perfect contrast. Its mucous membrane 
            stands out in unique clarity due to the black content of the bowel.
          </p>
        </div>
        <div className="card-container">
          <img className="card-icon" src={icon3d} alt="icon" />
          <p className="card-text">
            The Lumentin<sup>®</sup> 3-D software gives the radiologist access
            to a “virtual endoscopy” in high resolution.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Approach;
