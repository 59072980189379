import useFetch from "../../hooks/fetch/useFetch";
import { createMarkup } from "../utils/utils";
import "./Quotetop.scss";

interface QuoteTopProps {}

const Quotetop: React.FC<QuoteTopProps> = () => {
  const { data } = useFetch<any>("quotes");
  return (
    <section className="quote-top-container">
      {data && (
        <p
          className="text"
          dangerouslySetInnerHTML={createMarkup(data[0].acf.quote_one)}
        />
      )}
    </section>
  );
};

export default Quotetop;
