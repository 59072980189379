import useFetch from "../../../hooks/fetch/useFetch";
import "./Sidebar.scss";
import logo from "../../../assets/lument_logo_white.png";
import { ReactComponent as Close } from "../../../assets/close.svg";

export interface SidebarProps {
  open: boolean;
  toggleState: () => void;
  scroll: any;
}

const Nav = (scroll: any) => {
  const { data } = useFetch<any>("nav");
  return (
    <>
      {data && (
        <button
          className="nav-button"
          type="button"
          onClick={() => scroll("concept")}
        >
          {data[0].acf.link_one}
        </button>
      )}
      {data && (
        <button
          className="nav-button"
          type="button"
          onClick={() => scroll("approach")}
        >
          {data[0].acf.link_two}
        </button>
      )}
      {data && (
        <button
          className="nav-button"
          type="button"
          onClick={() => scroll("market")}
        >
          {data[0].acf.link_three}
        </button>
      )}
      {data && (
        <button
          className="nav-button"
          type="button"
          onClick={() => scroll("trials")}
        >
          {data[0].acf.link_four}
        </button>
      )}
      {data && (
        <button
          className="nav-button"
          type="button"
          onClick={() => scroll("people")}
        >
          {data[0].acf.link_five}
        </button>
      )}
    </>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ open, toggleState, scroll }) => {
  return (
    <div className={`sidebar-container ${open ? "open" : ""}`}>
      <div className="sidebar-background" onClick={toggleState} />
      <div className="sidebar">
        <div className="sidebar-navigation side-margin">
          <img src={logo} alt="logo" />
          <button
            aria-label="close sidebar"
            className="sidebar-close"
            onClick={toggleState}
          >
            <Close />
          </button>
        </div>
        <div className="sidebar-menu-items side-margin">{Nav(scroll)}</div>
      </div>
    </div>
  );
};

export default Sidebar;
