import React, { useEffect, useRef } from "react";
import "./App.css";

import Nav from "./components/Navbar";
import Hero from "./sections/Hero/Hero";
import Quotetop from "./sections/QuoteTop/Quotetop";
import Concept from "./sections/Concept/Concept";
import Approach from "./sections/Approach/Approach";
import QuoteMiddle from "./sections/QuoteMiddle/QuoteMiddle";
import Market from "./sections/Market/Market";
import QuoteMiddleSecond from "./sections/QuoteMiddleSecond/QuoteMiddleSecond";
import Trials from "./sections/Trials/Trials";
import QuoteBottom from "./sections/QuoteBottom/QuoteBottom";
import People from "./sections/People/People";
import Footer from "./components/Footer/Footer";
import { useHistory } from "react-router-dom";

//navigation
const App: React.FC = () => {
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const conceptRef = useRef<any>();
  const approachRef = useRef<any>();
  const marketRef = useRef<any>();
  const trialsRef = useRef<any>();
  const peopleRef = useRef<any>();
  
  const scrollToView = (view: string) => {
    if (view === "concept") {
      conceptRef.current.scrollIntoView();
      history.push('/concept')
    }
    if ( view === "approach") {
      approachRef.current.scrollIntoView();
      history.push('/approach')
    }
    if ( view === "market") {
      marketRef.current.scrollIntoView()
      history.push('/market')
    }
    if (view === "trials") {
      trialsRef.current.scrollIntoView()
      history.push('/trials')
    }
    if (view === "people") {
      peopleRef.current.scrollIntoView()
      history.push('/people')
    }
  };

  useEffect(() => {
    if (
      (pathname === "/concept" || pathname === "/concept/") &&
      conceptRef !== null &&
      conceptRef.current !== null
    ) {
      conceptRef?.current?.scrollIntoView();
    } 
    if (pathname === "/approach" || pathname === "/approach/" || pathname === "/about") {
      approachRef?.current?.scrollIntoView();
    }
    if (pathname === "/market" || pathname === "/market/") {
      marketRef?.current?.scrollIntoView()
    }
    if (pathname === "/trials" || pathname === "/trials/") {
      trialsRef?.current?.scrollIntoView()
    }
    if (pathname === "/people" || pathname === "/people" || pathname === "/team") {
      peopleRef?.current?.scrollIntoView()
    }
    
  }, [pathname]);

  return (
    <>
      <Nav scroll={scrollToView} />
      <Hero />
      <Quotetop />
      <Concept refProp={conceptRef} />
      <Approach refProp={approachRef} />
      <QuoteMiddle />
      <Market refProp={marketRef} />
      <QuoteMiddleSecond />
      <Trials refProp={trialsRef}/>
      <QuoteBottom />
      <People refProp={peopleRef}/>
      <Footer />
    </>
  );
};

export default App;
