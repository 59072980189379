import useFetch from "../../hooks/fetch/useFetch";
import useWindowSize from "../../hooks/useWindowSize";
import { getImageBySize } from "../utils/utils";
import "./ManagementTeam.scss";

// TypeScript - less bugs
interface Management {
  acf: {
    image: any; // define this one
    name: string;
    priority: string;
    title: string;
    type: string;
  };
  id: string;
}
const ManagementTeam: React.FC = () => {
  const { data } = useFetch<Management>("people");
  // Decides in what order the person will appear - priority from numbers in WP
  const sortByPrio = (list: Array<Management>) =>
    list.sort((a, b) => {
      const parsedA = parseInt(a.acf.priority);
      const parsedB = parseInt(b.acf.priority);
      return parsedA > parsedB ? 1 : parsedB > parsedA ? -1 : 0;
    });
  // Filter people by type (Management team, Board of Directors, Advisory Board from WP)
  const management = data?.filter((item) => item.acf.type === "managment");
  const windowSizes = useWindowSize();

  return (
    <section className="management-container">
      <h3>Management team</h3>
      <div className="card-wrapper">
        {management &&
          sortByPrio(management).map((item) => (
            <div key={item.id} className="card">
              <img
                className="image"
                src={getImageBySize(item.acf.image.sizes, windowSizes)}
                alt={item.acf.name}
              />
              <div className="text-container">
                <p className="text">{item.acf.name}</p>
                <p className="text">{item.acf.title}</p>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default ManagementTeam;
