import { useEffect, useState } from "react";

export type POST_TYPE =
  | "concept"
  | "approach"
  | "market"
  | "trials"
  | "people"
  | "quotes"
  | "nav"
  | "footer";

// fetch data from WP
const useFetch = <T>( // <T> stands for type
  postType: POST_TYPE
  ): { complete: boolean; data: Array<T> | undefined } => {
  const API_URL = "https://lumentab.com/api/wp-json/wp/v2/";
  const [complete, setComplete] = useState<boolean>(false);
  const [data, setData] = useState<Array<T>>();
  const fetchData = async ({ path }: { path: string }) => {
    const response = await fetch(path);
    const data = await response.json();

    setData(data);
    setComplete(true);
  };
  // path
  useEffect(() => {
    fetchData({ path: `${API_URL}${postType}?per_page=100` });
  }, [postType]);

  return { complete, data };
};

export default useFetch;



