import useFetch from "../../hooks/fetch/useFetch";
import useWindowSize from "../../hooks/useWindowSize";
import { getImageBySize } from "../utils/utils";
import "./BoardDirectors.scss"

// TypeScript - less bugs
interface Directors {
  acf: {
    image: any; // define this one
    name: string;
    priority: string;
    title: string;
    type: string;
  };
  id: string;
}

const BoardDirectors: React.FC = () => {
  const { data } = useFetch<Directors>("people");
  // Decides in what order the person will appear - prio from numbers in WP
  const sortByPrio = (list: Array<Directors>) =>
    list.sort((a, b) => {
      const parsedA = parseInt(a.acf.priority);
      const parsedB = parseInt(b.acf.priority);
      return parsedA > parsedB ? 1 : parsedB > parsedA ? -1 : 0;
    });
  const directors = data?.filter((item) => item.acf.type === "directors");
  const windowSizes = useWindowSize();
  return (
    <div className="directors-container">
      <h3>Board of directors</h3>
      <div className="card-wrapper">
        {directors &&
          sortByPrio(directors).map((item) => (
            <div key={item.id} className="card">
              <img
                className="image"
                src={getImageBySize(item.acf.image.sizes, windowSizes)}
                alt={item.acf.name}
              />
              <div className="text-container">
                <p className="text">{item.acf.name}</p>
                <p className="text">{item.acf.title}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BoardDirectors;
