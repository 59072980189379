import useFetch from "../../hooks/fetch/useFetch";
import "./AdvisoryBoard.scss";

interface Advisory {
  id: string;
  acf: {
    name: string;
    type: string;
    priority: string;
  };
}

const AdvisoryBoard = () => {
  const { data } = useFetch<Advisory>("people");
  // sort
  const sortByPrio = (list: Array<Advisory>) =>
    list.sort((a, b) => {
      const parsedA = parseInt(a.acf.priority);
      const parsedB = parseInt(b.acf.priority);
      return parsedA > parsedB ? 1 : parsedB > parsedA ? -1 : 0;
    });
  const advisory = data?.filter((item) => item.acf.type === "advisory");
  return (
    <div className="advisory-container">
      <h3>Advisory board</h3>
      {advisory &&
        sortByPrio(advisory).map((item) => (
          <div key={item.id}>
            <p className="text">{item.acf.name}</p>
          </div>
        ))}
    </div>
  );
};

export default AdvisoryBoard;
